import React, {useEffect} from 'react';
import Layout from '../../templates/layout';
import BasePageContent from '../../components/utils/BasePageContent';
import {bindActionCreators} from 'redux';
import {styled} from '@mui/material/styles';
import {navigate} from 'gatsby';
import {connect} from 'react-redux'
import * as actions from '../../actions';
import {Box, Button, Grid, Paper, Typography} from '@mui/material';
import RequestDetailsTable from "../../components/apiRequests/RequestDetailsTable";

const mapStateToProps = s => ({apiRequest: s.getAPIRequest});

const PREFIX = "ApiRequest";
const classes = {
  paper: `${PREFIX}-paper`,
  titleBox: `${PREFIX}-titleBox`,
  title: `${PREFIX}-title`,
  valueGridItem: `${PREFIX}-valueGridItem`,
  rowGridItem: `${PREFIX}-rowGridItem`,
  upper: `${PREFIX}-upper`,
  noDescription: `${PREFIX}-noDescription`,
}

const RootPaper = styled(Paper)(({theme}) => ({
  [`& .${classes.paper}`]: {
    height: '100%',
  }, [`& .${classes.titleBox}`]: {
    background: theme.palette.grey[100], color: '#000', fontWeight: 'bolder', padding: theme.spacing(2),
  }, [`& .${classes.title}`]: {
    fontWeight: 'bold',
  }, [`& .${classes.valueGridItem}`]: {
    wordWrap: 'break-word',
  }, [`& .${classes.rowGridItem}`]: {
    borderTop: '1px solid #e3e3e3', padding: `${theme.spacing(1)} ${theme.spacing(2)}`, // padding: `${theme.spacing(10)}px 0`,
  }, [`& .${classes.upper}`]: {
    textTransform: 'uppercase',
  }, [`& .${classes.noDescription}`]: {
    opacity: 0.6,
  },
}));

const ApiRequestDetails = ({code, actions, apiRequest}) => {

  useEffect(() => {
    actions.getAPIRequest(code);
  }, [code, actions]);

  const data = apiRequest?.data;

  return (<Layout>
    <BasePageContent loading={!apiRequest}>
      <Box my={2}>
        <Button
          variant='outlined'
          disableElevation
          onClick={() => navigate('/api-requests')}
        >
          Powrót do listy żądań API
        </Button>
      </Box>
      <RootPaper className={classes.paper} variant='outlined'>
        <Box textAlign='center' className={classes.titleBox}>
          <Typography variant='body1' className={classes.title}>
            Szczegóły żądania #{code}
          </Typography>
        </Box>
        {data && <Grid container spacing={2} p={2}>
          <Grid container item xs={12}>
            <RequestDetailsTable data={data}/>
          </Grid>
        </Grid>}
      </RootPaper>
    </BasePageContent>
  </Layout>);
}

const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators(actions, dispatch)});

export default connect(mapStateToProps, mapDispatchToProps)(ApiRequestDetails);
