import React from "react";
import JSONPretty from "react-json-pretty";

const mainStyle = 'line-height:1.3;color:#0243b3;background:#fafafa;overflow:auto;';

const theme = {
  main: mainStyle,
  error: mainStyle,
  key: 'color:#e01235;',
  string: 'color:#0243b3;',
  value: 'color:#fa7628;',
  boolean: 'color:#fa7628;',
};

const PrettyJSON = ({value}) => {
  if (!value) return <i>brak</i>;

  return (
    <JSONPretty
      data={value}
      theme={theme}
      style={{whiteSpace: 'pre-wrap'}}
    />
  )
}

export default PrettyJSON;
