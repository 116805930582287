import React, {useState} from "react";
import {Button, Collapse, TableCell, TableRow} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PrettyJSON from "./PrettyJSON";

const ToggleButton = ({isOpen, setIsOpen, hasValue, text}) => (
  <Button
    startIcon={isOpen ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
    aria-label="expand row"
    size="small"
    sx={{color: 'black'}}
    onClick={() => setIsOpen(!isOpen)}
    disabled={!hasValue}
  >
    {text}
  </Button>
);

const JSONRow = ({name, value, defaultOpen = true, colSpan = 1}) => {
  const [isOpen, setIsOpen] = useState(!!value && defaultOpen);

  const rowSx = {};
  if (value) {
    rowSx['& > *'] = {borderBottom: 'unset'};
  }

  return (<>
    <TableRow sx={rowSx}>
      <TableCell colSpan={value ? 1 : colSpan - 1}>
        <ToggleButton
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          text={name}
          hasValue={!!value}
        />
      </TableCell>
    </TableRow>
    {value && <TableRow>
      <TableCell sx={{py: 0}} colSpan={colSpan}>
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <PrettyJSON value={value}/>
        </Collapse>
      </TableCell>
    </TableRow>}
  </>);
}

export default JSONRow;
