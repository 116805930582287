import React from "react";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import JSONRow from "./JSONRow";

const RequestDetailsTable = ({data}) => {
  const columns = [
    {
      key: 'Metoda HTTP',
      value: data.httpMethod,
    },
    {
      key: 'URI',
      value: new URL(data.url || '').pathname
    },
    {
      key: 'Status',
      value: data.responseStatus
    },
    {
      key: 'Adres IP',
      value: data.remoteIp
    },
    {
      key: 'Czas przetwarzania',
      value: `${data.processingTime} ms`
    },
    {
      key: 'Wywołano',
      value: data.createdAt
    },
  ];

  const jsonRows = [
    {
      name: 'Nagłówki żądania',
      value: data.headers,
      defaultOpen: false,
    },
    {
      name: 'Parametry żądania',
      value: data.requestParams,
      defaultOpen: true,
    },
    {
      name: 'Ciało odpowiedzi',
      value: data.responseBody,
      defaultOpen: true,
    },
  ];

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map(({key}) => <TableCell>{key}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {columns.map(({value}) => <TableCell>{value}</TableCell>)}
          </TableRow>
          {jsonRows.map(({name, value, defaultOpen}) => (
            <JSONRow
              name={name}
              value={value}
              defaultOpen={defaultOpen}
              colSpan={columns.length}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default RequestDetailsTable;
